.reviews-carousel .slick-list {
  margin: 0 -10px;
}
.reviews-carousel .slick-track .slick-slide {
  display: flex;
  height: auto;
  align-items: stretch;
}

.reviews-carousel .slick-slide > div {
  padding: 0 10px;
  width: 100%;
}
