.product-slider .slick-slide {
  margin: 0 6px;
  max-width: calc(100vw - 40px);
}

.product-slider .slick-list {
  padding: 2px 26px !important;
  width: 100%;
  margin: 0 -6px;
}

.product-slider.initial .slick-track {
  display: flex !important;
  width: 100% !important;
  justify-content: flex-start;
}

.product-slider .slick-track::before {
  display: none;
}

.product-slider .slick-track::after {
  display: none;
}

@media screen and (min-width: 1024px) {
  .product-slider .slick-slide {
    margin: 0 10px;
  }
}

@media screen and (min-width: 1280px) {
  .product-slider .slick-list {
    padding-inline: 46px !important;
  }
}
