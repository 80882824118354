.tag-slider .slick-slide {
  max-width: fit-content;
}

.tag-slider .slick-track {
  display: flex !important;
  width: 100% !important;
  justify-content: flex-start;
  margin: 0 1.25rem;
  gap: 0.5rem;
}

.tag-slider .slick-track::before {
  display: none;
}

.tag-slider .slick-track::after {
  display: none;
}
