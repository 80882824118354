.testimonial-slider .slick-slide {
  margin: 0 6px;
  max-width: calc(100vw - 40px);
}

.testimonial-slider.initial .slick-track {
  display: flex !important;
  width: 100% !important;
  justify-content: flex-start;
}

.testimonial-slider .slick-track::before {
  display: none;
}

.testimonial-slider .slick-track::after {
  display: none;
}
